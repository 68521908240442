<template>
  <div id="app">
    <Main />
  </div>
</template>

<script>
import Main from './views/Main.vue'
import { mapActions, mapMutations } from 'vuex'

export default {
  name: 'App',
  components: {
    Main,
  },
  props: {
		customer: { type: String, default: null },
    projectId: { type: Number, default: null },
    version: { type: Number, default: 1 },
    template: { type: String, default: "default" },
    useSecondaryImage: { type: Boolean, default: false },
    useProjectImage: { type: Boolean, default: false },
    countryCode: { type: String, default: 'CL' },
    utmCampaign: { type: String, default: null },
		utmContent: { type: String, default: null },
		utmMedium: { type: String, default: null },
		utmSource: { type: String, default: null },
		utmTerm: { type: String, default: null },
    primaryColor: { type: String, default: '#000000'},
    secondaryColor: { type: String, default: '#686868'},
    useQuoteService: { type: Boolean, default: true },
    useOpportunityDiscount: { type: Boolean, default: true },
    noTitle: { type: Boolean, default: false },
    titleText: { type: String, default: 'Oportunidades' },
    subtitleText: { type: String, default: 'Encuentra el bien que más se adapte a ti' },
  },
  created() {
    this.setCustomer(this.customer)
    this.setTemplate(this.template)
    this.setNoTitle(this.noTitle)
    this.setTitle(this.titleText)
    this.setSubtitle(this.subtitleText)
    this.setDefaultProjectId(this.projectId)
    this.setVersion(this.version)
    this.fetchOpportunitiesData().then(() => {
      this.fetchProjectData()
      this.fetchDemographicAllData()
      this.fetchUfData()
    })
    this.setImagePreference(this.useSecondaryImage)
    this.setUseProjectImage(this.useProjectImage)
    this.setCountry(this.countryCode)
    this.setUtmData({
      utmCampaign: this.utmCampaign,
      utmContent: this.utmContent,
      utmMedium: this.utmMedium,
      utmSource: this.utmSource,
      utmTerm: this.utmTerm,
    })
    this.setThemeColors({
      primaryColor: this.primaryColor,
      secondaryColor: this.secondaryColor,
    })
    this.setQuoteService(this.useQuoteService)
    this.setQuoteDiscountLogic(this.useOpportunityDiscount)
  },
  methods: {
    ...mapActions('general', ['fetchProjectData', 'fetchUfData']),
    ...mapActions('opportunities', ['fetchOpportunitiesData']),
    ...mapActions('form', ['fetchDemographicAllData']),
    ...mapMutations('opportunities', ['setImagePreference']),
    ...mapMutations('general', ['setCustomer', 'setCountry', 'setUtmData', 'setThemeColors', 'setQuoteService', 'setQuoteDiscountLogic', 'setDefaultProjectId', 'setVersion', 'setTemplate', 'setUseProjectImage', 'setNoTitle', 'setTitle', 'setSubtitle']),
  }
}
</script>
<style lang="scss">
  mobysuite-opportunities {
    font-family: Helvetica, Arial, sans-serif;
  }

</style>
